import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../features/auth/authSlice"
import jwtDecode from 'jwt-decode'

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    let isPlayer = false
    let isSVL = false
    let isAdmin = false
    let status = 'Player'

    if (token) {
        const decoded = jwtDecode(token)
        const { id, username, roles, firstName, lastName, email, gender, region, sub, hitterSkillLevel, passerSkillLevel, setterSkillLevel, website, venmo } = decoded.UserInfo

        isPlayer = roles.includes('Player')
        isSVL = roles.includes('SVL')
        isAdmin = roles.includes('Admin')
        
        if (isPlayer) status = 'Player'
        if (isSVL) status = "SVL"
        if (isAdmin) status = "Admin"

        return { id, username, roles, status, isSVL, isAdmin, firstName, lastName, email, gender, region, sub, hitterSkillLevel, passerSkillLevel, setterSkillLevel, website, venmo }
    }

    return { username: '', roles: [], isSVL, isAdmin, status, firstName: '', lastName: '', email: '', gender: '', region: '', sub: '', hitterSkillLevel: [], passerSkillLevel: [], setterSkillLevel: [], website: '', venmo: '' }
}
export default useAuth