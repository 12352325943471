import { useState, useEffect } from "react"
import { useUpdateTeamMutation, useDeleteTeamMutation } from "./TeamApiSlice"
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"

const EditTeamForm = ({ team }) => {

    const { isAdmin, isSVL } = useAuth()

    const [updateTeam, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateTeamMutation()

    const [deleteTeam, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteTeamMutation()

    const navigate = useNavigate()
    
    const [teamId,] = useState(team.id)
    const [teamName, setTeamName] = useState(team.teamName)
    const [wins, setWins] = useState(team.wins)
    const [losses, setLosses] = useState(team.losses)
    const [paid, setPaid] = useState(team.paid)
    const [waitlist, setWaitlist] = useState(team.waitlist)

    useEffect(() => {

        if (isSuccess || isDelSuccess) {
            setTeamName('')
            setWins()
            setLosses()
            setPaid()
            setWaitlist()
            navigate('../games')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onTeamNameChanged = e => setTeamName(e.target.value)
    const onWinsChanged = e => setWins(e.target.value)
    const onLossesChanged = e => setLosses(e.target.value)

    const canSave = [teamName].every(Boolean) && !isLoading

    const onSaveTeamClicked = async (e) => {
        if (canSave) {
            await updateTeam({ id: teamId, teamName, wins, losses, paid, waitlist })
        }
    }

    const onDeleteTeamClicked = async () => {
        if(window.confirm(`Are you sure you want to delete ${teamName}?`) === true) {
            await deleteTeam({ id: team.id })
        } else {
            return 
        }
    }

    

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    let deleteButton = null
    if (isAdmin || isSVL) {
        deleteButton = (
            <button
                title="Delete"
                className="session-delete-button"
                onClick={onDeleteTeamClicked}
            >
                Delete Team
            </button>
        )
    }

    const content = (
        <>
        
        <h2><span className="edit-session-title">Update Team - </span>{team.teamName}</h2>
            <p className={errClass}>{errContent}</p>
            <form className="form" onSubmit={e => e.preventDefault()}>
                {isAdmin && <div className="form__title-row">
                    <h2 className="edit-session-subtitle">{team.name}</h2>
                    <label className="form__label" htmlFor="teamName">
                        Team Name:</label>
                    <input
                        className=""
                        id="teamName"
                        name="teamName"
                        type="text"
                        value={teamName}
                        onChange={onTeamNameChanged}
                    />
                </div>}

                    <label className="form__label" htmlFor="wins">
                        Wins:</label>
                    <input
                        className=""
                        id="wins"
                        name="wins"
                        type="number"
                        value={wins}
                        onChange={onWinsChanged}
                        onWheel={(e) => e.target.blur()}
                    />
                    <label className="form__label" htmlFor="losses">
                        Losses:</label>
                    <input
                        className=""
                        id="losses"
                        name="losses"
                        type="number"
                        value={losses}
                        onChange={onLossesChanged}
                        onWheel={(e) => e.target.blur()}
                    />
                    <div >
                        <button
                            title="Save"
                            className="session-update-button"
                            onClick={onSaveTeamClicked}
                            disabled={!canSave}
                        >
                            Update Team
                        </button>
                        {deleteButton}
                    </div>
            </form>
        </>
    )

    return content
}

export default EditTeamForm