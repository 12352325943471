import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import { useAddNewLeagueMutation } from "./leagueApiSlice"

const NewLeagueForm = () => {

    const [addNewLeague, {
        isSuccess,
        isError,
        error
    }] = useAddNewLeagueMutation()

    const navigate = useNavigate()
    const { venmo, website } = useAuth()

    const [court, setCourt] = useState("")
    const [startDateTime, setStartDateTime] = useState()
    const [numberOfWeeks, setNumberOfWeeks] = useState()
    const [timeIncrement, setTimeIncrement] = useState()
    const [skillLevel, setSkillLevel] = useState("")
    const [format, setFormat] = useState("")
    const [genderRequirement, setGenderRequirement] = useState("")
    const [day, setDay] = useState("")
    const [maxTeams, setMaxTeams] = useState()
    const [cost, setCost] = useState()
    const [cash, setCash] = useState()
    const [svl, setSvl] = useState(sessionStorage.svl)
    //eslint-disable-next-line
    const [svlName, setSvlName] = useState(sessionStorage.svlName)
    const [sessionId, setSessionId] = useState(sessionStorage.sessionId)
    const [sessionName, setSessionName] = useState(sessionStorage.sessionName)

    useEffect(() => {
        if (isSuccess) {
            setCourt("")
            setDay("")
            setFormat("")
            setCost()
            setSvl("")
            setSessionId("")
            setSessionName("")
            navigate('/dash/leagues')
        }
    }, [isSuccess, navigate])

    const onCourtChanged = e => setCourt(e.target.value)
    const onStartDateTimeChanged = e => setStartDateTime(e.target.value)
    const onNumberOfWeeksChanged = e => setNumberOfWeeks(e.target.value)
    const onTimeIncrementChanged = e => setTimeIncrement(e.target.value)
    const onSkillLevelChanged = e => setSkillLevel(e.target.value)
    const onFormatChanged = e => setFormat(e.target.value)
    const onGenderRequirementChanged = e => setGenderRequirement(e.target.value)
    const onDayChanged = e => setDay(e.target.value)
    const onMaxTeamsChanged = e => setMaxTeams(e.target.value)
    const onCostChanged = e => setCost(e.target.value)
    const onCashChanged = e => setCash(e.target.value)

    const cashOnly = () => {
        if (svl !== "stooges") {
            return
        } else {
            return (<>
            <label className="form__label">
            Cash Only:</label>
        <select
            className=""
            value={cash}
            onChange={onCashChanged}
        >
            <option value={""}></option>
            <option value={true}>True</option>
            <option value={false}>False</option>
        </select>
        </>)
        }
    }

    

    const canSave = [court, startDateTime, numberOfWeeks, timeIncrement, skillLevel, format, day,  maxTeams, cost].every(Boolean)

    const onSaveLeagueClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewLeague({ session: sessionId, svl, svlName, sessionName: sessionName, court, startDateTime, numberOfWeeks, timeIncrement, skillLevel, format, genderRequirement, day, maxTeams, cost, cash, scheduleGenerated: false, url: website, venmo})
        }
    }

    const errClass = isError ? "errmsg" : "offscreen"

    const content = (
        <>
            <p className={errClass}>{error?.data?.message}</p>

            <form className="form" onSubmit={onSaveLeagueClicked}>
                <div className="form__title-row">
                    <h2 className="league-title">New League</h2>
                </div>
                <label className="form__label" htmlFor="court">
                    Court:</label>
                <input
                    className=""
                    id="court"
                    name="court"
                    type="text"
                    value={court}
                    onChange={onCourtChanged}
                />
                <label className="form__label">
                    Day:</label>
                <select
                    className=""
                    value={day}
                    onChange={onDayChanged}
                >
                    <option value=""></option>
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                </select>

                <label className="form__label" htmlFor="startDateTime">
                    Start Date/Time (Select day of week league is played):</label>
                <input
                    className=""
                    type="datetime-local"
                    id="startDateTime"
                    name="startDateTime"
                    value={startDateTime}
                    onChange={onStartDateTimeChanged}
                />
                <label className="form__label" htmlFor="numberOfWeeks">
                    Number of Weeks:</label>
                <input
                    className=""
                    type="number"
                    id="numberOfWeeks"
                    name="numberOfWeeks"
                    value={numberOfWeeks}
                    onWheel={(e) => e.target.blur()}
                    onChange={onNumberOfWeeksChanged}
                />

                <label className="form__label" htmlFor="timeIncrement">
                    Time Increment(minutes between games):</label>
                <input
                    className=""
                    type="number"
                    id="timeIncrement"
                    name="timeIncrement"
                    value={timeIncrement}
                    onWheel={(e) => e.target.blur()}
                    onChange={onTimeIncrementChanged}
                />
                <label className="form__label">
                    Skill Level:</label>
                <select
                    className=""
                    value={skillLevel}
                    onChange={onSkillLevelChanged}
                >
                    <option value=""></option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C+">C+</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                </select>

                <label className="form__label">
                    Format:</label>
                <select
                    className=""
                    value={format}
                    onChange={onFormatChanged}
                >
                    <option value=""></option>
                    <option value="doubles">Doubles</option>
                    <option value="quads">Quads</option>
                    <option value="5s">5s</option>
                    <option value="6s">6s</option>
                    <option value="reverseQuads">Reverse Quads</option>
                </select>

                <label className="form__label" htmlFor="genderRequirement">
                    Gender Requirement:</label>
                <input
                    className=""
                    id="genderRequirement"
                    name="genderRequirement"
                    type="text"
                    value={genderRequirement}
                    onChange={onGenderRequirementChanged}
                />
                <label className="form__label" htmlFor="maxTeams">
                    Max Teams:</label>
                <input
                    className=""
                    type="number"
                    id="maxTeams"
                    onWheel={(e) => e.target.blur()}
                    name="maxTeams"
                    value={maxTeams}
                    onChange={onMaxTeamsChanged}
                />
                <label className="form__label" htmlFor="cost">
                    League Cost:</label>
                <input
                    className=""
                    type="number"
                    id="cost"
                    onWheel={(e) => e.target.blur()}
                    name="cost"
                    value={cost}
                    onChange={onCostChanged}
                />
                {cashOnly()}
                    <div>
                        <button
                            className="new-session-button"
                            title="Save"
                        >
                            Create New League
                        </button>
                    </div>
            </form>
        </>
    )

    return content
}

export default NewLeagueForm