import { useState, useEffect } from "react"
import { useUpdateLeagueMutation, useDeleteLeagueMutation } from "./leagueApiSlice"
import { useGetTeamsQuery } from "../teams/TeamApiSlice"
import { useAddNewGameMutation } from "../games/gameApiSlice"
import { useNavigate, Link } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import moment from "moment"

const EditLeagueForm = ({ league }) => {

    const { isSVL, isAdmin } = useAuth()

    const [updateLeague, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateLeagueMutation()

    const [deleteLeague, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteLeagueMutation()

    const navigate = useNavigate()

    const [leagueId, setLeagueId] = useState(league.id)
    const [active, setActive] = useState(league.active)
    const [court, setCourt] = useState(league.court)
    const [startDateTime, setStartDateTime] = useState(league.startDateTime)
    const [numberOfWeeks, setNumberOfWeeks] = useState(league.numberOfWeeks)
    const [timeIncrement, setTimeIncrement] = useState(league.timeIncrement)
    const [skillLevel, setSkillLevel] = useState(league.skillLevel)
    const [format, setFormat] = useState(league.format)
    const [genderRequirement, setGenderRequirement] = useState(league.genderRequirement)
    const [day, setDay] = useState(league.day)
    const [maxTeams, setMaxTeams] = useState(league.maxTeams)
    const [cost, setCost] = useState(league.cost)
    const [cash, setCash] = useState(league.cash)
    // eslint-disable-next-line
    const [stage, setStage] = useState(league.stage)
    //eslint-disable-next-line
    const [session, setSession] = useState(league.session)
    const [svl, setSvl] = useState(league.svl)
    // eslint-disable-next-line
    const [svlName, setSvlName] = useState(league.svlName)
    const [teamName] = useState("")
    const [tournamentPng, setTournamentPng] = useState(league.tournamentPng)
    const [scheduleGenerated] = useState(league.scheduleGenerated)
    const url = league.url
    const venmo = league.venmo

    useEffect(() => {
        if (isSuccess || isDelSuccess) {
            setCourt('')
            setDay('')
            setFormat('')
            setCost()
            setLeagueId('')
            setSession('')
            setSvl("")
            navigate('/dash/leagues')
        }
    }, [isSuccess, isDelSuccess, navigate])

    const onCourtChanged = e => setCourt(e.target.value)
    const onActiveChanged = e => setActive(e.target.value)
    const onStartDateTimeChanged = e => setStartDateTime(e.target.value)
    const onNumberOfWeeksChanged = e => setNumberOfWeeks(e.target.value)
    const onTimeIncrementChanged = e => setTimeIncrement(e.target.value)
    const onSkillLevelChanged = e => setSkillLevel(e.target.value)
    const onFormatChanged = e => setFormat(e.target.value)
    const onDayChanged = e => setDay(e.target.value)
    const onMaxTeamsChanged = e => setMaxTeams(e.target.value)
    const onCostChanged = e => setCost(e.target.value)
    const onCashChanged = e => setCash(e.target.value)
    const onGenderRequirementChanged = e => setGenderRequirement(e.target.value)

    //players per league format for pricing
    const playersPerTeam = () => {
        if (league.format === "Doubles") {
            return 2
        } else if (league.format === "5s") {
            return 5
        } else if (league.format === "6s") {
            return 6
        } else {
            return 4
        }
    }

    //base64 reader
    const convertToBase64 = (e) => {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.onloadend = () => {
            setTournamentPng(reader.result.toString())
        }
        reader.readAsDataURL(file)
    }

    //setting session storage items for checkout
    const volleysubFee = 5 * playersPerTeam()
    const amount = league.cost
    sessionStorage.setItem("amount", amount)
    sessionStorage.setItem("volleysubFee", volleysubFee)
    sessionStorage.setItem("teamName", teamName)
    sessionStorage.setItem("svl", league.svl)
    sessionStorage.setItem("leagueName", league.svl +" "+ league.day +" "+ league.skillLevel +" "+ league.format)
    sessionStorage.setItem("leagueId", league.id)
    sessionStorage.setItem("maxTeams", league.maxTeams)
    sessionStorage.setItem("cash", league.cash)
    sessionStorage.setItem("venmo", league.venmo)

    const canSave = [court, startDateTime, numberOfWeeks, timeIncrement, skillLevel, format, day, maxTeams, cost].every(Boolean) && !isLoading

    const onSaveLeagueClicked = async (e) => {
        if (canSave) {
            await updateLeague({ id:leagueId, svl, svlName, stage, court, startDateTime, numberOfWeeks, timeIncrement, skillLevel, format, day, maxTeams, cost, cash, active, scheduleGenerated, tournamentPng: tournamentPng, url, venmo })
        }
    }

    const startDate = new Date(startDateTime).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })

    const onDeleteLeagueClicked = async () => {
        if(window.confirm(`Are you sure you want to delete League: ${sessionStorage.leagueName}?`) === true) {
        await deleteLeague({ id: league.id })
        } else {
            return
        }
    }

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''
    const name = `${league.day} ${league.skillLevel} ${league.format}`

    let deleteButton = null
    if (isSVL || isAdmin) {
        deleteButton = (
            <button
                title="Delete"
                className="session-delete-button"
                onClick={onDeleteLeagueClicked}
            >
                Delete League
            </button>
        )
    }

    //cash only option for Stooges ONLY
    const cashOnly = () => {
        if (svl !== "stooges") {
            return
        } else {
            return (<>
            <label className="form__label">
            Cash Only:</label>
        <select
            className=""
            value={cash}
            onChange={onCashChanged}
        >
            <option value={true}>True</option>
            <option value={false}>False</option>
        </select>
        </>)
        }
    }

    //tournament display
    const displayTournament = () => {
        if (!tournamentPng) {
            return <p>Tournament Bracket not released yet. Check back later!</p>
        } else {
            return <img alt="Tournament" src={tournamentPng}/>
        }
    }



    //SCHEDULE GENERATION
        //getting teams
    const { teams }  = useGetTeamsQuery("teamsList", {
        selectFromResult: ({ data }) => ({
            teams: data?.entities
        }),
    }) 
        //filtering down to an array of team names
    const filteredTeams = Object.values(teams).filter((league) => league.league === leagueId && league.waitlist === false)
    const sortedArray = filteredTeams.sort((a,b) => (a.id > b.id) ? 1 : -1)
    const teamArray = sortedArray.map((teamName) => teamName.teamName)

    const [addNewGame] = useAddNewGameMutation()

    const minutes = timeIncrement
    const gameArray = JSON.parse(JSON.stringify(teamArray))

    const createSchedule = async () => {

        let matchTime = new Date(startDateTime)
        const numberOfRounds = league.numberOfWeeks
        const type = "League"

        for (let i = 0; i < numberOfRounds; i++) {

            for (let j = 0; j < maxTeams / 2; j++) {

                if (gameArray[j] === gameArray[gameArray.length - 1 - j]) {
                    await addNewGame({league: leagueId, team1: "BYE", team2: "BYE", dateTime: matchTime, type, round: i, match: j })
                } else {
                    await addNewGame({league: leagueId, team1: gameArray[j], team2: gameArray[gameArray.length - 1 - j], dateTime: matchTime, type, round: i, match: j })
                }
                matchTime = moment(matchTime).add(minutes, 'm').toDate()
            }
            matchTime = moment(matchTime).subtract(minutes * (maxTeams/2), "m").toDate()
            matchTime = moment(matchTime).add(7, 'days').toDate()
            gameArray.splice(gameArray.length/2, 0, gameArray[gameArray.length - 1])
            gameArray.pop()
            gameArray.splice(0, 0, gameArray[gameArray.length - 1])
            gameArray.pop()
        }
        await updateLeague({ id:leagueId, stage, svl, svlName, court, startDateTime, numberOfWeeks, timeIncrement, skillLevel, format, day, maxTeams, cost, cash, active: true, scheduleGenerated: true, tournamentPng: tournamentPng, url, venmo })
    }


    //User Interface
    const content = (
        <>
            <h2><span className="league-title">League - </span>{svlName} : {day} {skillLevel} {format}</h2> 
            <div className="sub-links">
                <h4>League Info:</h4>
                <h5>Session: {league.sessionName}</h5>
                <h5>Start Date: {startDate}</h5>
                <h5>Season Length: {league.numberOfWeeks} Weeks</h5>
                <h5>Court: {court}</h5>
                <h5>Gender Requirement: {!genderRequirement ? "None" : genderRequirement}</h5>
            </div>
            <h3 className="league-title">League Actions</h3>
            <div className="sub-links">
            {(isAdmin) && <p className="svl-links"><Link to="../teams/TESTschedule" className="links">TEST SCHEDULE</Link></p>}
            <p className="svl-links"><Link to="../teams/new" className="links">Register New Team</Link></p>
            <p className="svl-links"><Link to="../teams" className="links">View Standings</Link></p>
            {(isAdmin) && <p className="svl-links"><Link to="../teams/waitlist" className="links">View Waitlist</Link></p>}
            {(isSVL || isAdmin) && scheduleGenerated === false && <p className="svl-links" onClick={createSchedule}>Generate Schedule</p>}
            <p className="svl-links"><Link to="../teams/schedule" className="links">View Schedule</Link></p>
            </div>
            <details className="sub-links">
                <summary>View Tournament</summary>
                <div>{displayTournament()}</div>
            </details>
        { (isSVL || isAdmin) && <details>
        <summary className="edit-session-title sub-links">Edit League - {name}</summary>
            <p className={errClass}>{errContent}</p>

            <form className="form" onSubmit={e => e.preventDefault()}>
                <div className="form__title-row">
                    <h2 className="edit-session-subtitle">{name}</h2>

                </div>
                <label className="form__label" htmlFor="active">
                    Active:</label>
                <select
                    className=""
                    id="active"
                    value={active}
                    onChange={onActiveChanged}
                >
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                </select>
                <label className="form__label" htmlFor="league-court">
                    Court:</label>
                <input
                    className=""
                    id="league-court"
                    name="court"
                    type="text"
                    value={court}
                    onChange={onCourtChanged}
                />
                <label className="form__label" htmlFor="day">
                    Day:</label>
                <select
                    className=""
                    id="day"
                    value={day}
                    onChange={onDayChanged}
                >
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday</option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                </select>

            <label className="form__label" htmlFor="startDateTime">
                    Start Date/Time:</label>
                <input
                    className=""
                    type="datetime-local"
                    id="startDateTime"
                    name="startDateTime"
                    value={startDateTime}
                    onChange={onStartDateTimeChanged}
                />

            <label className="form__label" htmlFor="numberOfWeeks">
                    Number of Weeks:</label>
                <input
                    className=""
                    type="number"
                    id="numberOfWeeks"
                    name="numberOfWeeks"
                    value={numberOfWeeks}
                    onWheel={(e) => e.target.blur()}
                    onChange={onNumberOfWeeksChanged}
                />

                <label className="form__label" htmlFor="timeIncrement">
                    Time Increment(minutes):</label>
                <input
                    className=""
                    type="number"
                    id="timeIncrement"
                    name="timeIncrement"
                    value={timeIncrement}
                    onChange={onTimeIncrementChanged}
                    onWheel={(e) => e.target.blur()}
                />
                <label className="form__label" htmlFor="skillLevel">
                    Skill Level:</label>
                <select
                    className=""
                    id="skillLevel"
                    value={skillLevel}
                    onChange={onSkillLevelChanged}
                >
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C+">C+</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                </select>
                
                <label className="form__label" htmlFor="format">
                    Format:</label>
                <select
                    className=""
                    id="format"
                    value={format}
                    onChange={onFormatChanged}
                >
                    <option value="doubles">Doubles</option>
                    <option value="quads">Quads</option>
                    <option value="5s">5s</option>
                    <option value="6s">6s</option>
                    <option value="reverseQuads">Reverse Quads</option>
                </select>

                <label className="form__label" htmlFor="genderRequirement">
                    Gender Requirement:</label>
                <input
                    className=""
                    id="genderRequirement"
                    name="genderRequirement"
                    type="text"
                    value={genderRequirement}
                    onChange={onGenderRequirementChanged}
                />

                <label className="form__label" htmlFor="maxTeams">
                    Max Teams:</label>
                <input
                    className=""
                    type="number"
                    id="maxTeams"
                    name="maxTeams"
                    value={maxTeams}
                    onChange={onMaxTeamsChanged}
                    onWheel={(e) => e.target.blur()}
                />
                <label className="form__label" htmlFor="cost">
                    League Cost $:</label>
                <input
                    className=""
                    type="number"
                    id="cost"
                    name="cost"
                    value={cost}
                    onChange={onCostChanged}
                    onWheel={(e) => e.target.blur()}
                />
                    {cashOnly()}
                <label className="form__label" htmlFor="tournamentPng">
                    Tournament</label>
                <input
                    className=""
                    type="file"
                    name="tournamentPng"
                    accept=".png, application/pdf, application/msword, image/jpeg"
                    onChange={e => convertToBase64(e)}
                />
                    <div >
                        <button
                            title="Save"
                            className="session-update-button"
                            onClick={onSaveLeagueClicked}
                            disabled={!canSave}
                        >
                            Update League
                        </button>
                        {deleteButton}
                    </div>
            </form>
            </details>}
        </>
    )

    return content
}

export default EditLeagueForm