import { useNavigate } from "react-router-dom"

const PaymentSuccess = () => {

    const teamName = sessionStorage.teamName
    const leagueName = sessionStorage.leagueName
    const id = sessionStorage.leagueId
    const amount = sessionStorage.amount
    const venmo = sessionStorage.venmo

    const navigate = useNavigate()

    const backToLeagues = () => {
        navigate(`/dash/leagues/${id}/teams`)
    }

    return (
        <>
        <h2 className="league-title">Signup Successful!</h2>
        <div className="success-details">
        <h4>Thank you for signing up {teamName} in {leagueName} </h4>
        <h6>Please submit league fee payment of ${amount} to @{venmo} on venmo.</h6>
        <h6>Payment must be submitted at least one week before league start date or your spot may be given to a waitlisted team.</h6>
        </div>
        <button onClick={backToLeagues} className="session-update-button">Return to Standings</button>
        </>
    )
}

export default PaymentSuccess