import { Link } from 'react-router-dom'
import VSMultiHeader from './VSLoginHeader';
import VSMultiFooter from './VSLoginFooter';

const Public = () => {
    const content = (
        <div>
            <VSMultiHeader />
            <div className='public-header league-title'>
                <h2>Your home for volleyball leagues, tournaments, and subs</h2>
            </div>
            <section className="public league-title">
                <Link to="/login">Login</Link>
                <Link to="/new">Signup</Link>
                <Link to="/aboutUs">About Us</Link>
                <Link to="/franchise">Franchise</Link>
                <Link to="/host">Host</Link>
                <Link to="/promote">Promote</Link>
                <Link to="/contact">Contact</Link>
            </section>
            <VSMultiFooter />
        </div>
    )
    return content
}
export default Public