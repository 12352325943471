import React from 'react'
import VSLoginHeader from './VSLoginHeader'
import VSLoginFooter from './VSLoginFooter'
import coverPicture from '../Facebook Cover Image.png'
import '../multiForms.css'


const AboutUs = () => {

    return (
        <div>
        <VSLoginHeader />
            <div className='aboutUsContainer'>
                <h1 className='sentMessageTitle'>About Us</h1>
                <img src={coverPicture} alt=""></img>
                <p>Our mission: Enhance the game of volleyball and provide quality playing experiences to all.</p>
                <p>Volleysub is a sand volleyball league and technology startup out of Lexington, KY. What started as volleyball league management software
                has quickly evolved into an organization providing both the platform to run a league and operating the league itself. Why the change?
                It became clear to us that there are simply not enough opportunities to play the sport that we all love. Along with local volleyball communities, 
                we will be starting league franchises, hosting the league and registration on our site, and providing the operational framework to guarantee league success 
                and longevity.</p>
                <p>We are proud to announce that the first Volleysub league will be launching in March 2025 in Lexington, KY.</p>
                <p>Want to run your own Volleysub league franchise in your city? Fill out the form on the franchise page and we will be in touch.</p>
                <p> 
                    Volleysub is always looking for tech, volleyball, and sales talent. If Volleysub sounds like a company you 
                    would like to work for, send us a note on the contact page.
                </p>
            </div>
            
        <VSLoginFooter />
        </div>
    )
}

export default AboutUs